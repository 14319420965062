import type { PropsWithChildren } from "react"
import { createContext, useState } from "react"

import { Emojis } from "@/features/emoji/ui/Emojis"

export const EmojisContext = createContext<{
  sendEmoji: () => void
  closeEmoji: () => void
}>({
  closeEmoji: () => {},
  sendEmoji: () => {},
})

export function EmojisProvider({ children }: PropsWithChildren<any>) {
  const [show, setShow] = useState<boolean>(false)

  const sendEmoji = () => setShow(!show)
  const closeEmoji = () => setShow(!show)
  return (
    <EmojisContext.Provider value={{ closeEmoji, sendEmoji }}>
      {children}
      {show && <Emojis />}
    </EmojisContext.Provider>
  )
}
