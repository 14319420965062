import { create } from "zustand"
import type { GameState, GameType } from "@durak/common"
import type { Room } from "colyseus.js"

interface State {
  game: GameType
  gameId: string | null
  isRedirected: boolean
  playerGotCards: number | null
  playerPutCard: number | null
  playerTookCards: number | null
  room: Room<GameState>
}

interface Actions {
  clearPlayerGotCards: () => void
  clearPlayerPutCard: () => void
  clearPlayerTookCards: () => void
  setIsRedirected: (isRedirected: State["isRedirected"]) => void
  setPlayerGotCards: (playerGotCards: State["playerGotCards"]) => void
  setPlayerPutCard: (playerPutCard: State["playerPutCard"]) => void
  setPlayerTookCards: (playerTookCards: State["playerTookCards"]) => void
  updateGame: (game: State["game"]) => void
  updateGameId: (gameId: State["gameId"]) => void
  updateRoom: (room: State["room"]) => void
}

export const useGame = create<State & Actions>((set) => ({
  game: {} as GameType,
  room: {} as Room<GameState>,
  gameId: null,
  playerPutCard: null,
  playerTookCards: null,
  playerGotCards: null,
  isRedirected: false,

  updateGame: (game) => set(() => ({ game })),
  updateGameId: (gameId) => set(() => ({ gameId })),
  updateRoom: (room) => set(() => ({ room })),
  setPlayerPutCard: (playerPutCard) => set(() => ({ playerPutCard })),
  setPlayerTookCards: (playerTookCards) => set(() => ({ playerTookCards })),
  setPlayerGotCards: (playerGotCards) => set(() => ({ playerGotCards })),
  clearPlayerTookCards: () => set(() => ({ playerTookCards: null })),
  clearPlayerPutCard: () => set(() => ({ playerPutCard: null })),
  clearPlayerGotCards: () => set(() => ({ playerGotCards: null })),
  setIsRedirected: (isRedirected) => set(() => ({ isRedirected })),
}))
