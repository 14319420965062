import type { PropsWithChildren } from "react"
import { createContext, useCallback, useRef } from "react"

import type { MessageInfo } from "@/features/toast/ui/ToastHub"
import { ToastHub } from "@/features/toast/ui/ToastHub"

type ToastFunction = (
  message: string,
  style: MessageInfo["style"],
  duration?: number
) => void

type AddToastFunction = (params: {
  message: string
  style: MessageInfo["style"]
  duration: number
}) => void

export const ToastContext = createContext<ToastFunction | undefined>(undefined)

export function ToastProvider({ children }: PropsWithChildren<any>) {
  const addToastRef = useRef<AddToastFunction | null>(null)

  const addToast = useCallback(
    (message: string, style: MessageInfo["style"], duration = 4000) => {
      if (addToastRef.current) addToastRef.current({ message, style, duration })
    },
    []
  )

  return (
    <ToastContext.Provider value={addToast}>
      {children}
      <ToastHub>
        {(add: AddToastFunction) => {
          addToastRef.current = add
        }}
      </ToastHub>
    </ToastContext.Provider>
  )
}
