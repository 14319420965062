import { create } from "zustand"

import type { DimensionProps } from "../hooks/useInitDimensions"

interface State {
  dimension: DimensionProps
}

interface Actions {
  updateDimension: (dimension: State["dimension"]) => void
}

export const useDimension = create<State & Actions>((set) => ({
  dimension: {} as DimensionProps,
  updateDimension: (dimension) => set(() => ({ dimension })),
}))
