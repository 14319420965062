import { Suspense, useEffect } from "react"
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom"
import { routes } from "@/pages/routes"
import Loading from "@/shared/ui/Loading"
import {
  useClosingBehavior,
  useMiniApp,
  useSwipeBehavior,
  useViewport,
} from "@telegram-apps/sdk-react"
import posthog from "posthog-js"

export const RouterProvider = () => {
  const miniApp = useMiniApp()
  const swipeBehavior = useSwipeBehavior()
  const viewport = useViewport()
  const closingConfirmation = useClosingBehavior()

  useEffect(() => {
    viewport?.expand()
    closingConfirmation?.enableConfirmation()
    if (miniApp?.supports("requestWriteAccess"))
      void miniApp?.requestWriteAccess()
    if (swipeBehavior?.supports("disableVerticalSwipe"))
      swipeBehavior.disableVerticalSwipe()
  }, [viewport, closingConfirmation, swipeBehavior])

  useEffect(() => {
    miniApp.ready()
  }, [miniApp])

  useEffect(() => {
    posthog?.capture("started_app")
  }, [])

  return (
    <Router>
      <Suspense fallback={<Loading />}>
        <Routes>
          {routes.map(({ path, Component }) => (
            <Route key={path} path={path} element={<Component />} />
          ))}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Suspense>
    </Router>
  )
}
