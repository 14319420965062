import ReactDOM from "react-dom/client"

import { EmojiProvider } from "./providers/EmojiProvider"
import { EmojisProvider } from "./providers/EmojisProvider"
import { QueryProvider } from "./providers/QueryProvider"
import { RouterProvider } from "./providers/RouterProvider"
import { ThemeProvider } from "./providers/ThemeProvider"
import { ErrorBoundaryError, TMAProvider } from "./providers/TMAProvider"
import { ToastProvider } from "./providers/ToastProvider"
import { PostHogProvider } from "posthog-js/react"
import posthog from "posthog-js"
import * as Sentry from "@sentry/react"

import "./styles/index.css"

import "@/shared/i18n/i18n"

if (
  typeof window !== "undefined" &&
  !window.location.host.includes("127.0.0.1") &&
  !window.location.host.includes("localhost")
)
  posthog.init(import.meta.env.VITE_POSTHOG_API_KEY, {
    api_host: import.meta.env.VITE_POSTHOG_HOST,
    person_profiles: "identified_only",
  })

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
    Sentry.browserProfilingIntegration(),
    Sentry.browserApiErrorsIntegration(),
    Sentry.captureConsoleIntegration(),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: [/^https:\/\/(?!.*(posthog)).*\.funfiesta\.games/],
  replaysSessionSampleRate: 1,
  replaysOnErrorSampleRate: 1.0,
  profilesSampleRate: 1.0,
})

ReactDOM.createRoot(document.getElementById("root")!).render(
  <Sentry.ErrorBoundary fallback={ErrorBoundaryError} showDialog>
    <PostHogProvider client={posthog}>
      <TMAProvider>
        <ThemeProvider storageKey="vite-ui-theme">
          <QueryProvider>
            <ToastProvider>
              <EmojiProvider>
                <EmojisProvider>
                  <RouterProvider />
                </EmojisProvider>
              </EmojiProvider>
            </ToastProvider>
          </QueryProvider>
        </ThemeProvider>
      </TMAProvider>
    </PostHogProvider>
  </Sentry.ErrorBoundary>
)
