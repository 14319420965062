import { SDKProvider } from "@telegram-apps/sdk-react"
import { type ReactNode } from "react"

export const ErrorBoundaryError = ({ error }: { error: unknown }) => (
  <div>
    <p>An unhandled error occurred:</p>
    <blockquote>
      <code>
        {error instanceof Error
          ? error.message
          : typeof error === "string"
            ? error
            : JSON.stringify(error)}
      </code>
    </blockquote>
    <p>Please, try to reload the page.</p>
    <p>If the error persists, please, contact the admins.</p>
  </div>
)

export const TMAProvider = ({ children }: { children?: ReactNode }) => {
  return <SDKProvider acceptCustomStyles>{children}</SDKProvider>
}
