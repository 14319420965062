import { type ComponentType, lazy, type ReactNode } from "react"

const CreateGamePage = lazy(() => import("./CreateGame"))
const MainPage = lazy(() => import("./Main"))
const GameWrapper = lazy(() => import("./Game"))
const DailyPage = lazy(() => import("./Daily"))
const CustomsPage = lazy(() => import("./Customs"))
const ProfilePage = lazy(() => import("./Profile"))
const LeaderboardPage = lazy(() => import("./Leaderboard"))
const ExchangeTokensPage = lazy(() => import("./ExchangeTokens"))

interface Route {
  Component: ComponentType
  icon?: ReactNode
  path: string
  title?: string
}

export const routes: Route[] = [
  { path: "/", Component: MainPage },
  { path: "/create", Component: CreateGamePage },
  { path: "/game", Component: GameWrapper },
  { path: "/daily", Component: DailyPage },
  { path: "/customs", Component: CustomsPage },
  { path: "/profile", Component: ProfilePage },
  { path: "/leaderboard", Component: LeaderboardPage },
  { path: "/exchange", Component: ExchangeTokensPage },
]
