import { retrieveLaunchParams } from "@telegram-apps/sdk"
import en from "./locales/en.json"
import ru from "./locales/ru.json"
import i18n from "i18next"
import { initReactI18next } from "react-i18next"

const { initData } = retrieveLaunchParams()

i18n.use(initReactI18next).init({
  lng: initData?.user?.languageCode,
  resources: { en: { ...en }, ru: { ...ru } },
  fallbackLng: "en",
})
