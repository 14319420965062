import { animated, useTransition } from "@react-spring/web"
import type { CSSProperties, MouseEvent } from "react"
import { useEffect, useMemo, useState } from "react"
import { useDimension } from "@/shared/store/dimensions-store"

let id = 0

interface MessageHubProps {
  children: (add: AddFunction) => void
}

export interface MessageInfo {
  duration: number
  message: string
  style: "warning" | "error" | "info"
}

export type AddFunction = (info: MessageInfo) => void

interface Item extends MessageInfo {
  key: number
}

const bgColor = {
  error: "rgb(55, 41, 41)",
  info: "#1E1E1E",
  warning: "rgb(55, 41, 41)",
}

const textColor = {
  error: "#DA5151",
  info: "#ffffff",
  warning: "#DA5151",
}

const animationConfig = { friction: 20, precision: 0.1, tension: 125 }

export function ToastHub({ children }: MessageHubProps) {
  const { playerSize } = useDimension((state) => state.dimension)
  const refMap = useMemo(() => new WeakMap(), [])
  const cancelMap = useMemo(() => new WeakMap(), [])
  const [items, setItems] = useState<Item[]>([])
  const [queue, setQueue] = useState<MessageInfo[]>([])

  const transitions = useTransition(items, {
    config: (item, _, phase) => (key) =>
      phase === "enter" && key === "life"
        ? { duration: item.duration }
        : animationConfig,
    enter: (item) => async (next, cancel) => {
      cancelMap.set(item, cancel)
      await next({ height: refMap.get(item).offsetHeight, opacity: 1 })
      await next({ life: "0%" })
    },
    from: { height: 0, life: "100%", opacity: 0 },
    keys: (item) => item.key,
    leave: [{ opacity: 0 }, { height: 0 }],
    onRest: (_, __, item) => {
      setItems((state) =>
        state.filter((i) => {
          return i.key !== item.key
        })
      )
    },
  })

  useEffect(() => {
    children(({ duration, message, style }) => {
      setQueue((q) => [...q, { duration, message, style }])
    })
  }, [])

  useEffect(() => {
    if (items.length < 2 && queue.length > 0) {
      const nextItem = queue.shift()
      if (nextItem) setItems((state) => [...state, { ...nextItem, key: id++ }])
    }
  }, [items, queue])

  return (
    <div className="pointer-events-none fixed left-1/2 top-2 z-50 w-max -translate-x-1/2 transform items-center">
      {transitions(({ life, ...style }, item) => (
        <animated.div
          className="relative box-border overflow-hidden mb-1"
          style={style as unknown as CSSProperties}
        >
          <div
            className="flex items-center gap-3 overflow-hidden rounded-xl px-3 py-2"
            ref={(ref: HTMLDivElement) => ref && refMap.set(item, ref)}
            style={{
              backgroundColor: bgColor[item.style],
              color: textColor[item.style],
            }}
          >
            <p className="flex-1 text-center text-xs">{item.message}</p>

            <button
              type="button"
              className="shrink-0 bg-transparent"
              style={{ pointerEvents: "auto" }}
              onClick={(e: MouseEvent<HTMLButtonElement>) => {
                e.stopPropagation()

                if (cancelMap.has(item) && life.get() !== "0%")
                  cancelMap.get(item)()
              }}
            >
              <svg
                style={{
                  height: `${playerSize * 0.2}px`,
                  width: `${playerSize * 0.2}px`,
                }}
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
        </animated.div>
      ))}
    </div>
  )
}
