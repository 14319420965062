import type { PropsWithChildren } from "react"
import { createContext, useCallback, useState } from "react"

type EmojiFunction = (emoji: string, playerFrom: number) => void

interface PropsEmoji {
  emojis: EmojiContent[]
  showEmoji: EmojiFunction
}

export interface EmojiContent {
  emoji: string
  playerFrom: number
  uid: string
}

export const emojiDuration = 4000

export const EmojiContext = createContext<PropsEmoji>({} as PropsEmoji)

export function EmojiProvider({ children }: PropsWithChildren<any>) {
  const [emojis, setEmojis] = useState<EmojiContent[]>([])

  const showEmoji = useCallback((emoji: string, playerFrom: number) => {
    const addedElement = Math.random().toString(36).substring(2, 15)
    setEmojis((prev) => [...prev, { emoji, playerFrom, uid: addedElement }])

    setTimeout(() => {
      setEmojis((prev) => prev.filter((e) => e.uid !== addedElement))
    }, emojiDuration)
  }, [])

  return (
    <EmojiContext.Provider value={{ emojis, showEmoji }}>
      {children}
    </EmojiContext.Provider>
  )
}
